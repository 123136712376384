<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-icon left>mdi-message-text-fast</v-icon>
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Booking Requests ({{ totalBookings }})</v-toolbar-title
      >

      <v-spacer></v-spacer>
      <v-tooltip>
        <template v-slot:activator="{ on }">
          <v-btn
            color="#383d3f"
            class="mr-1"
            icon
            right
            @click="exportList"
            v-on="on"
            :disabled="loading"
          >
            <v-icon>mdi-export-variant</v-icon>
          </v-btn>
        </template>
        <span>Export to Excel</span>
      </v-tooltip>
    </v-app-bar>

    <div
      class="mx-4"
      id="booking-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-tabs>
          <v-tab
            @change="
              defaultStatus = 'pending'
              activeAction()
            "
          >
            Pending
          </v-tab>
          <v-tab
            @change="
              defaultStatus = 'accepted'
              activeAction()
            "
          >
            Accepted
          </v-tab>
          <v-tab
            @change="
              defaultStatus = 'done'
              activeAction()
            "
          >
            Done
          </v-tab>
          <v-tab
            @change="
              defaultStatus = 'declined'
              activeAction()
            "
          >
            Declined
          </v-tab>
          <v-tab
            @change="
              defaultStatus = 'cancelled'
              activeAction()
            "
          >
            Cancelled
          </v-tab>
          <v-tab
            @change="
              defaultStatus = null
              activeAction()
            "
          >
            All
          </v-tab>
        </v-tabs>
        <v-spacer />
        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search user, service and building"
          @input="search"
        ></v-text-field>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-10"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              v-show="false"
            >
              Bulk Actions
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in actions" :key="index">
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <v-data-table
        :headers="headers"
        :items="bookings"
        :server-items-length="totalBookings"
        :options.sync="options"
        :loading="loading"
        v-model="selected"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        @click:row="editItem"
        @update:options="fetch"
      >
        <template v-slot:item.user="{ item }">
          {{ item.userName }}
        </template>
        <template v-slot:item.bookable="{ item }">
          {{ item.serviceTitle }}
        </template>
        <template v-slot:item.type="{ item }">
          {{ item.serviceType }}
        </template>
        <template v-slot:item.datetime_start="{ item }">
          {{ item.bookingDate }}
        </template>
        <template v-slot:item.property="{ item }">
          {{ item.user?.property?.name }}
        </template>
        <template v-slot:item.building="{ item }">
          {{ item.buildingName }}
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.addedDate }}
        </template>
        <template v-slot:item.status="{ item }">
          <div style="text-align: center">
            <Status :status="item.statusLabel" />
          </div>
        </template>
      </v-data-table>
      <div
        class="d-flex justify-center mt-3"
        v-if="!(bookings.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Booking list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import Status from '@/components/common/Status'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import BOOKING_STATUS from '@/utils/enums/BookingStatus'

export default {
  name: 'BookingsPage',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
    Status,
  },

  data() {
    return {
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
      },
      defaultStatus: 'pending',
      defaultFilter: 'created_at',
      sortOrder: false,
      headers: [
        {
          text: 'User',
          value: 'user',
          width: '13%',
        },
        {
          text: 'Request',
          value: 'bookable',
          width: '14%',
        },
        {
          text: 'Type',
          value: 'type',
          width: '10%',
        },
        {
          text: 'Property',
          value: 'property',
          width: '10%',
        },
        {
          text: 'Building',
          value: 'building',
          width: '10%',
        },
        {
          text: 'Requested Date',
          value: 'datetime_start',
          width: '20%',
        },
        {
          text: 'Status',
          value: 'status',
          width: '10%',
          align: 'center',
        },
        {
          text: 'Added date',
          value: 'created_at',
          width: '13%',
        },
      ],
      actions: [
        {
          value: 'delete',
          label: 'Delete',
        },
      ],
      pageTables: 0,
      itemsPerPageTables: 0,
      selected: [],
    }
  },

  created() {
    this.clearBookings()
    this.fetch()
  },

  computed: {
    ...mapState({
      bookings: (state) => state.booking.list,
      listMeta: (state) => state.booking.listMeta,
      totalBookings: (state) => state.booking.totalBookings,
    }),

    statusFilter() {
      return [
        {
          text: 'All',
          value: null,
        },
        ...BOOKING_STATUS,
        {
          text: 'Deleted',
          value: 'deleted',
        },
      ]
    },

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },
  },

  methods: {
    ...mapActions({
      getBookings: 'booking/getBookings',
      exportToExcel: 'booking/exportToExcel',
    }),

    ...mapMutations({
      clearBookings: 'booking/clearBookingList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
    }),

    filterList(filterValue) {
      this.clearBookings()
      this.fetch(null, filterValue)
    },

    activeAction() {
      this.clearBookings()
      this.fetch()
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetch(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearBookings()
      this.fetch()
    }, 600),

    async fetch(options, page = 1) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalBookings
            : options.itemsPerPage
          : 25,
        filter: this.defaultStatus,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      await this.getBookings(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
        })
    },
    editItem(item) {
      this.$router.push({
        name: 'booking.details',
        params: { id: item.id },
      })
    },

    async exportList() {
      this.setLoadingBuilding(true)
      this.loading = true
      const params = {
        filter: this.defaultStatus,
      }
      await this.exportToExcel(params)
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'bookings_list.xlsx')
          document.body.appendChild(link)
          link.click()
          this.showSnackbar('File successfully downloaded')
          this.setLoadingBuilding(false)
        })
        .catch((err) => {
          this.showSnackbar(
            this.getErrorMessage(err, 'Oops! Something went wrong'),
            false
          )
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  &__status {
    width: 100%;
    max-width: 300px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__status {
      margin-bottom: 15px;
    }

    &__status,
    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}
.v-list-item__title {
  cursor: pointer;
}
</style>
